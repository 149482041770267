<template lang="pug">
  div
    section.section.section-lg.section-shaped.overflow-hidden.my-0
      router-view

      div.container
        div.row.justify-content-center.text-center.mb-3(:v-show="activeStaff")
        div.row(:v-show="activeStaff")
          div.mb-5.mb-lg-0(:class="getColumnSizeStyle()" v-for="staff in activeStaff" :key="staff.staffId")
            a.px-4.text-center(:href="pushTo(staff)")
              img.rounded-circle.img-center.img-fluid.shadow.shadow-lg--hover.thumb(v-lazy="getImageData(staff.image)")
              h5.title
                span.d-block.mb-1.pt-3 {{ staff.name }}
                small.h6.text-muted {{ staff.position }}
              div(v-show="staff.rate")
                star-rating.mt-1.mb-4.d-flex.justify-content-center(
                  :star-size="20"
                  :show-rating="false"
                  :round-start-rating="false"
                  v-model="staff.rate"
                  :readOnly="true")
        h2.mt-5.width-100.main-title.text-center {{ $t("issist.main.services") }}
        div.mb-lg-0(v-for="group in getGroups()" :key="group")
          h5.title 
            h3.d-block.mb-1.pt-3.ml-5 {{ group }}
            section.section.section-lg.pt-0.pb-2
              div.container.col-sm-12
                  div.p-5.mt-1.border-top
                    div.mb-2.row.align-items-center.pt-2(v-for="service in getServices(group)" :key="service.name")
                      div.col-lg-10
                        span {{ service.name }}
                        p.text-muted {{ service.description }}
                      div.col-lg-2
                        p {{ getServicePrice(service) }}

    //- section.section.section-lg.pt-0
    //-   div.container
    //-     card.border-0( gradient="warning" no-body shadow-size="lg")
    //-       div.p-5
    //-         div.row.align-items-center
    //-           div.col-lg-8
    //-             h3.text-white Making an appointment becomes easier
    //-             p.lead.text-white.mt-3 In a couple of clicks you can make an appointment
    //-           div.col-lg-3.ml-lg-auto
    //-             router-link(to="./services")
    //-               base-button(type="white" block size="lg") Make an appointment
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import utils from "@/utils";
import { FETCH_STAFF_LIST, FETCH_SERVICES_LIST } from "@/store/actions.type";
import StarRating from "vue-star-rating";

export default {
  name: "StaffList",
  components: {
    StarRating
  },
  computed: {
    ...mapGetters(['organization', 'activeStaff', "services"])
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.key == null) {
      to.params.key = utils.companyName()
    }

    Promise.all([
      store.dispatch(FETCH_STAFF_LIST, to.params.key),
      store.dispatch(FETCH_SERVICES_LIST, to.params.key),
    ]).then(() => {
      next();
    }).catch((error) => {
      console.log(error.message)
    });
  },
  metaInfo() {
    return {
      title: this.organization.name,
      meta: [
        {
          name: 'description',
          content: 'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'
        },
        { property: 'og:title', content: this.organization.name },
        { property: 'og:site_name', content: this.organization.name },
        { property: 'og:image', content: this.getImageData(this.organization.logo) },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  methods: {
    getImageData(key) {
      if (key) {
        return utils.thumbnailImageUrlWithId(key)
      } else {
        return `/img/theme/default-user.png`
      }
    },
    pushTo(staff) {
      return `/rate/${staff.staffId}`
      // this.$router.push({ name: "comments", params: { key: this.$route.params.key, staffId: staff.staffId } })
    },
    getColumnSizeStyle() {
      if (this.activeStaff.length <= 3) {
        return `col-lg-${12 / this.activeStaff.length}`
      } else {
        return "col-lg-3"
      }
    },
    getGroups() {
      let sorted = Object.keys(this.services).sort((a, b) => {
        return (this.services[a][0].group?.groupPosition ?? 1000) - (this.services[b][0].group?.groupPosition ?? 1000);
      });
      return sorted
    },
    getServices(group) {

      return this.services[group]
    },
    getServicePrice(service) {

      let format = new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: service.currency,
      });
      if (service.maxPrice == service.priceOrigin) {
        return format.format(service.priceOrigin / 100.0)

      } else {
        return this.$t('issist.main.priceFrom') + " " + format.format(service.priceOrigin / 100.0)
      }
    },
    openTelegram() {
      window.location.href = "https://t.me/" + this.organization.telegramBot;
    }
  }
}
</script>

<style scoped>
.thumb {
  width: 180px;
  height: 180px;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
}

.main-title {
  width: 180px;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
}

.width-100 {
  width: 100%;
}
</style>