<template lang="pug">
  div
    section.section.section-lg
      div
        p Services

</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { FETCH_COMPANY_DETAILS } from "@/store/actions.type";
import router from "@/router";

export default {
  name: "Services",
  computed: {
    ...mapGetters(['organization', 'activeStaff', "services"])
  },
  beforeRouteEnter(to, from, next) {

    Promise.all([
      store.dispatch(FETCH_COMPANY_DETAILS, to.params.key),
    ]).then(() => {
      next();
    }).catch((error) => {
      console.log(error.message)
      router.push({ path: '/404' });
    });
  },
}
</script>

<style scoped></style>