<template>
  <div class="profile-page my-5 pt-5">

    <section class="section">
      <div class="container">
        <div class="px-4">
          <div class="row">

            <div class="col-lg-12 d-flex justify-content-center">
              <img v-lazy="getAvatarUrl()" src="img/theme/default-user.png" class="rounded-circle thumb">


            </div>

          </div>
          <div class="text-center mt-4">
            <h3>{{ getName() }}</h3>
            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ appointmentDetails.staff.position }}
            </div>
            <div v-show="appointmentDetails.staff.rate" class="align-baseline">

              <star-rating :inline="true" :star-size="16" :read-only="true" :show-rating="false"
                :rating="this.appointmentDetails.staff.rate" :increment="0.01" :max-rating="5"></star-rating>
            </div>
          </div>
          <div class="mt-1 py-5 border-top text-center">
            <div class="text-center mb-4">
              <small>{{ $t('issist.post.putFeedback') }} </small>
              <h4>{{ appointmentDetails.appointmentName }}</h4>
              <p>{{ appointmentDetails.appointmentDate | formatDate }}</p>
            </div>

            <!--  FORM  -->
            <form role="form" class="px--5" @submit.prevent="onPublish()">
              <fieldset :disabled="inProgress">
                <div>
                  <star-rating :star-size="30" :show-rating="false" :round-start-rating="false" v-model="comment.rate"
                    @rating-selected="notSelectRate = false" min="0" class="mt-1 mb-4 "></star-rating>
                  <p v-show="notSelectRate && comment.rate" class="text-warning" style="text-align: start">Рейтинг не
                    выбран</p>
                  <textarea class="form-control" rows="3" v-model="comment.comment"
                    :placeholder="$t('issist.post.putFeedback')"></textarea>
                </div>
              </fieldset>
              <div :v-show="errors">
                <p class="text-warning" v-for="error in errors" :key="error">{{ error }}</p>
              </div>
              <div class="row-flex-wrap w-100">

                <base-checkbox v-model="comment.anonymously" class="col-lg-6 order-lg-1 align-self-center border-start-0"
                  type="default">
                  {{ $t('issist.post.anonymously') }}
                </base-checkbox>
                <button class="col-lg-6 order-lg-1 btn btn-lg pull-xs-right btn-primary my-4" :disabled="inProgress"
                  type="default" round block size="lg">{{ $t('issist.post.submit') }}
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import API_URL from '@/common/config';
import store from "@/store";
import { FETCH_APPOINTMENT_DETAILS, POST_COMMENT } from "@/store/actions.type";
import { mapGetters } from "vuex";
import StarRating from 'vue-star-rating'
// import VueNumberFormat from "vue-number-format";

export default {
  name: "PostComment",
  computed: {
    ...mapGetters(['appointmentDetails'])
  },
  components: {
    StarRating
  },
  data() {
    return {
      languageValue: String,
      inProgress: false,
      notSelectRate: true,
      errors: [],
      comment: {}
    };
  },
  beforeRouteEnter(to, from, next) {

    Promise.all([
      store.dispatch(FETCH_APPOINTMENT_DETAILS, to.params.postKey)
    ]).then(() => {
      next();
    }).catch((error) => {
      console.log(error.message)
      this.$router.push({ path: `/404` })
    });
  },
  metaInfo() {
    return {
      title: "Epiloge - Build your network in your field of interest",
      meta: [
        {
          name: 'description',
          content: 'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'
        },
        { property: 'og:title', content: "Epiloge - Build your network in your field of interest" },
        { property: 'og:site_name', content: 'Epiloge' },
        { property: 'og:image', content: this.getAvatarUrl() },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  beforeMount() {

    this.comment.anonymously = false
    this.comment.appointmentId = this.appointmentDetails.appointmentId
  },
  methods: {
    getAvatarUrl() {
      if (this.appointmentDetails.staff.image != null) {
        return `${API_URL}fs/file/${this.appointmentDetails.staff.image}/thumbnail`
      } else {
        return `img/theme/default-user.png`
      }
    },
    getName() {
      return this.appointmentDetails.staff.name.trim()
    },
    onPublish() {

      this.errors = [];

      if (this.errors.length !== 0 || this.notSelectRate) {
        return
      }

      this.inProgress = true;
      this.$store
        .dispatch(POST_COMMENT, this.comment)
        .then(() => {
          this.inProgress = false;
          this.$router.push({ path: `/thanks` })
        })
        .catch((response) => {
          this.inProgress = false;

          let data = response.response.data;
          this.errors.push(data.localizationMessage ? data.localizationMessage : data.message);
        });
    },
  }
}


</script>

<style scoped>
.thumb {
  width: 180px;
  height: 180px;
  object-fit: cover;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
}

.max-width-800 {
  max-width: 800px;
}

@media (max-width: 575.98px) {

  .card-margin-top {
    margin-top: 110px;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 650px;
  }
}

@media (min-width: 768px) {
  .px--5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 620px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 940px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1120px;
  }
}
</style>