<template lang="pug">
  nav#mainNav.navbar.navbar-expand-lg.navbar-light.fixed-top.shadow-sm
    .container.px-5
      img.app-badge(src="issist/logo.svg" href='/')
      button.navbar-toggler(type='button', data-bs-toggle='collapse', data-bs-target='#navbarResponsive', aria-controls='navbarResponsive', aria-expanded='false', aria-label='Toggle navigation')
        h4
          i.bi-list.me-2
      #navbarResponsive.collapse.navbar-collapse
        div
          button.float-right.navbar-toggler(type='button', data-bs-toggle='collapse', data-bs-target='#navbarResponsive', aria-controls='navbarResponsive', aria-expanded='false', aria-label='Toggle navigation')
            i.bi-x-lg
        ul.navbar-nav.ms-auto.me-4.my-3.my-lg-0
          li.nav-item
            a.nav-link.me-lg-3(v-on:click="scrollTo('features')") {{ $t('issist.nav.features')}}
          li.nav-item
            a.nav-link.me-lg-3(v-on:click="scrollTo('download')") {{ $t('issist.nav.app')}}
        button.btn.btn-primary.rounded-pill.px-3.mb-2.mb-lg-0(data-bs-toggle='modal', v-on:click="openTelegram()")
          span.d-flex.align-items-center
            span.small Feedback
        
        


</template>

<script>
import Menu2 from "@/views/components/Navigation/Menu2";

export default {
  name: "NavigationLanding",
  data() {
    return {
      menu: Menu2
    };
  },
  beforeMount() {

    this.languageValue = this.$route.query.l ?? localStorage.getItem('lang') ?? "ua"
    if (this.$route.query.l) {
      this.changeLanguage()
    }
  },
  methods: {

    changeLanguage() {
      localStorage.setItem("lang", this.languageValue)
      if (this.$i18n.locale !== this.languageValue) {
        this.$i18n.locale = this.languageValue;
      }
    },
    scrollTo(el) {
      document.getElementById(el).scrollIntoView({ behavior: "smooth" });
    },
    openTelegram() {
      window.location.href = "https://t.me/issist_bot?start=feedback";
    }
  }
}
</script>

<style scoped></style>