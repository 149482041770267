<template lang="pug">
  section.section.section-shaped.section-lg.my-0.h-85vh
    div.container.pt-lg-md
      div.row.justify-content-center
        div.col-lg-5.mx-2
          section.message.is-http-404
            h1.fs-4 404
            h3 {{ $t('issist.404.pageLookingFor') }}
            p {{ $t('issist.404.pageMoved') }}
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>