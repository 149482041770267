<template lang="pug">
  footer.bg-black.text-center.py-3
    .container
      .row.row-grid.text-white-50.small
        .mb-lg-0(v-for="place in organization.places" :key="place.locationName")
          .col.col-lg-12
            span.d-block.mb-1.mt-2 {{ place.name }}
            a(target="_blank" rel="noopener noreferrer" :href="openGoogleMap(place.coordinate.latitude, place.coordinate.longitude )")
              span.px-2 {{ place.locationName }}
            div.mb-lg-0(v-for="phone in place.phoneNumbers" :key="phone")
              i.fa.fa-phone.mx-3
              a(:href="getPhoneLink(phone)") {{ "+" + phone }}

      hr
      .text-white-50.small
        .mb-2 &copy; iss.ist {{year}}. All Rights Reserved.
        a(href='/privacy-policy') Privacy
        span.mx-1 &middot;
        a(href='/terms-and-conditions') Terms
        span.mx-1 &middot;
        a(href='/eula') EULA
        span.mx-1 &middot;
        a(href='/cookie-policy') Cookie Policy
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'app-footer',
  computed: {
    ...mapGetters(['organization'])
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    getPhoneLink(phone) {
      return "tel:+" + phone
    },
    openGoogleMap(lat, long) {
      return "https://www.google.com/maps/search/?api=1&query=" + lat + "+" + long
    },
  }
}
</script>
<style></style>