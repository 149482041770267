<template lang="pug">
  div
    // Mashead header
    header#page-top.masthead
      .container.px-5
        .row.gx-5.align-items-center
          .col-lg-6
            // Mashead text and app badges
            .mb-5.mb-lg-0.text-lg-start
              h1.display-4.lh-1.mb-3  {{ $t('issist.landing.greatSolution')}}
              p.lead.fw-normal.text-muted.mb-5 {{ $t('issist.landing.organizingMeeting')}}
                .d-flex.flex-column.flex-lg-row.align-items-center
                //a.me-lg-3.mb-4.mb-lg-0(href='#!')
                //    img.app-badge(src='assets/img/google-play-badge.svg', alt='...')
                a(href='https://apps.apple.com/ua/app/issist/id1602515830')
                  img.app-badge(src="issist/app-store-badge.svg", alt='...')
          .col-lg-6
            // Masthead device mockup feature
            .masthead-device-mockup
              include ../../includes/gradient-circle
              include ../../includes/shape-1
              include ../../includes/shape-2
              .device-wrapper
                .device(data-device='iPhoneMain', data-orientation='portrait', data-color='black')
                  //- .screen.bg-black
                    //- img(src="issist/main.png", type='image/jpeg' style='max-width: 100%; height: 100%;')

    // Quote/testimonial aside
    aside.text-center.bg-gradient-primary-to-secondary
      .container.px-5
        .row.gx-5.justify-content-center
          .col-xl-8
            .h2.fs-1.text-white.mb-4  {{ $t('issist.landing.intuitive')}} 
            //img(src='assets/img/tnw-logo.svg', alt='...', style='height: 3rem;')

    // App features section
    section#features
      .container.px-5
        .row.gx-5.align-items-center
          .col-lg-8.order-lg-1.mb-5.mb-lg-0
            .container-fluid.px-5
              .row.gx-5
                .col-md-6.mb-5
                  // Feature item
                  .text-center
                    i.bi-telegram.icon-feature.text-gradient.d-block.mb-3
                    h3.font-alt {{ $t('issist.landing.personalBot')}} 
                    p.text-muted.mb-0 {{ $t('issist.landing.easy')}} 
                .col-md-6.mb-5
                  // Feature item
                  .text-center
                    i.bi-telephone-x.icon-feature.text-gradient.d-block.mb-3
                    h3.font-alt {{ $t('issist.landing.appointment')}} 
                    p.text-muted.mb-0 {{ $t('issist.landing.makeEasier')}}  
              .row
                .col-md-6.mb-5.mb-md-0
                  // Feature item
                  .text-center
                    i.bi-app-indicator.icon-feature.text-gradient.d-block.mb-3
                    h3.font-alt {{ $t('issist.landing.notifications')}} 
                    p.text-muted.mb-0 {{ $t('issist.landing.noticeNewAppointment')}} 
                .col-md-6
                  // Feature item
                  .text-center
                    i.bi-people.icon-feature.text-gradient.d-block.mb-3
                    h3.font-alt {{ $t('issist.landing.team')}} 
                    p.text-muted.mb-0 {{ $t('issist.landing.workWithTeam')}} 
          .col-lg-4.order-lg-0
            // Features section device mockup
            .features-device-mockup
              include ../../includes/gradient-circle
              include ../../includes/shape-1
              include ../../includes/shape-2
              //include ../../includes/shape-3
              .device-wrapper
                .device(data-device='iPhoneSecond', data-orientation='portrait', data-color='black')
                  //- .screen.bg-black
                    // PUT CONTENTS HERE:
                    // * * This can be a video, image, or just about anything else.
                    // * * Set the max width of your media to 100% and the height to
                    // * * 100% like the demo example below.
                    img(src="issist/calendar.jpg", type='image/jpeg' style='max-width: 100%; height: 100%;')

    // Basic features section
    section.bg-light
      .container.px-5
        .row.gx-5.align-items-center.justify-content-center.justify-content-lg-between
          .col-12.col-lg-5
            h2.display-4.lh-1.mb-4 {{ $t('issist.landing.newLook')}}
            p.lead.fw-normal.text-muted.mb-5.mb-lg-0 {{ $t('issist.landing.meetingManagementProblem')}}
          .col-sm-8.col-md-6
            .px-5.px-sm-0
              img.img-fluid.rounded-circle(src='issist/deal.jpg', alt='...')

    // Call to action section
    section#download.cta
      .cta-content
        .container.px-5
          h2.text-white.display-1.lh-1.mb-4
            |{{ $t('issist.landing.stopWaiting')}}
            br
            | {{ $t('issist.landing.startBy')}}
          a.btn.btn-outline-light.py-3.px-4.rounded-pill(href='https://apps.apple.com/ua/app/issist/id1602515830', target='_blank')  {{ $t('issist.landing.download')}}

    // App badge section
    section.bg-gradient-primary-to-secondary
      .container.px-5
        h2.text-center.text-white.font-alt.mb-4 {{ $t('issist.landing.getApp')}}
        .d-flex.flex-column.flex-lg-row.align-items-center.justify-content-center
          //a.me-lg-3.mb-4.mb-lg-0(href='#!')
          //    img.app-badge(src='assets/img/google-play-badge.svg', alt='...')
          a(href='https://apps.apple.com/ua/app/issist/id1602515830')
            img.app-badge(src='issist/app-store-badge.svg', alt='...')

    // Feedback modal
    #feedbackModal.modal.fade(tabindex='-1', aria-labelledby='feedbackModalLabel', aria-hidden='true')
      .modal-dialog.modal-dialog-centered
        .modal-content
          .modal-header.bg-gradient-primary-to-secondary.p-4
            h5#feedbackModalLabel.modal-title.font-alt.text-white Send feedback
            button.btn-close.btn-close-white(type='button' data-bs-dismiss='modal', aria-label='Close')
          .modal-body.border-0.p-4
            form
              .form-floating.mb-4
                input#inputName.form-control(type='text', placeholder='Enter your name...')
                label(for='inputName') Name
              .form-floating.mb-4
                input#inputEmail.form-control(type='email', placeholder='name@example.com')
                label(for='inputEmail') Email address
              .form-floating.mb-4
                input#inputPhone.form-control(type='tel', placeholder='(123) 456-7890')
                label(for='inputPhone') Phone number
              .form-floating.mb-4
                textarea#inputMessage.form-control(placeholder='Enter your message here...', style='height: 10rem;')
                label(for='inputMessage') Message
              .d-grid
                button.btn.btn-primary.rounded-pill.py-3(type='submit') Submit

</template>

<script>
export default {
  name: "home",
  components: {},
  created() {
      var vbot =  this.$route.query.vbot
      if (vbot != null) {
        window.location.href = "viber://pa?chatURI=" + vbot;
      }
    },
};
</script>

<style>

.navbar-collapse {
  flex-grow: 0 !important;
  align-items: center;
}
</style>
