<template lang="pug">
  footer.bg-black.text-center.py-5
    .container.px-5
      .text-white-50.small
        .mb-2 &copy; iss.ist {{years()}}. All Rights Reserved.
        a(href='/privacy-policy') Privacy
        span.mx-1 &middot;
        a(href='/terms-and-conditions') Terms
        span.mx-1 &middot;
        a(href='/eula') EULA
        span.mx-1 &middot;
        a(href='/cookie-policy') Cookie Policy
        //- span.mx-1 &middot;
        //- a(href='/terms-and-conditions') FAQ
</template>

<script>
export default {
  name: "Footer",
  methods: {
    years() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped></style>