<template>
  <base-nav type="default" effect="dark" expand :title="organization.name" :content-id="`navbar-default`">
    <router-link :to="companyLink()" slot="brand">


      <h4> <img v-show="organization.logo" class="thumb mx-3" v-lazy="logoUrl()" alt="logo">{{ organization.name }}</h4>
    </router-link>
    <div class="row" slot="content-header" slot-scope="{closeMenu}">
      <div class="col-6 collapse-brand">
        <img class="thumb mx-3" v-lazy="logoUrl()" alt="logo">
      </div>
      <div class="col-6 collapse-close">
        <close-button @click="closeMenu" :target="`navbar-default`">

        </close-button>
      </div>
    </div>

    <ul class="navbar-nav ml-lg-auto">
      <li class="nav-item" v-show="organization.facebook">
        <a class="nav-link nav-link-icon" :href="getUrl(organization.facebook)" target="_blank" rel="noopener"
          data-toggle="tooltip" title="Like us on Facebook">
          <i class="fa fa-facebook-square"></i>
          <span class="nav-link-inner--text d-lg-none">Facebook</span>
        </a>
      </li>
      <li class="nav-item" v-show="organization.instagram">
        <a class="nav-link nav-link-icon" :href="getUrl(organization.instagram)" target="_blank" rel="noopener"
          data-toggle="tooltip" title="Follow us on Instagram">
          <i class="fa fa-instagram"></i>
          <span class="nav-link-inner--text d-lg-none">Instagram</span>
        </a>
      </li>
      <li class="nav-item" v-show="organization.twitter">
        <a class="nav-link nav-link-icon" :href="getUrl(organization.twitter)" target="_blank" rel="noopener"
          data-toggle="tooltip" title="Follow us on Twitter">
          <i class="fa fa-twitter-square"></i>
          <span class="nav-link-inner--text d-lg-none">Twitter</span>
        </a>
      </li>
    </ul>
    <!-- <vue-fab v-show="organization.telegramBot" fabAutoHideAnimateModel="alive" fabAliveAnimateBezier="linear"  mainBtnColor="#3e3e3e" 
     title="Tooltip on top" 
    size="big" icon="book"></vue-fab>  -->

  </base-nav>
</template>

<script>
import store from "@/store";
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import { mapGetters } from "vuex";
import utils from "@/utils";
import { FETCH_COMPANY_DETAILS } from "@/store/actions.type";

export default {

  components: {
    BaseNav,
    CloseButton,
  },
  computed: {
    ...mapGetters(['organization'])
  },
  beforeRouteEnter(to, from, next) {

    if (to.params.key == null) {
      to.params.key = utils.companyName()
    }

    Promise.all([
      store.dispatch(FETCH_COMPANY_DETAILS, to.params.key),
    ])
    .then(() => {
      next();
    })
    .catch((error) => {
      console.log(error.message)
      window.location.href = "https://iss.ist";
    });
  },
  beforeMount() {

    this.languageValue = this.$route.query.l ?? localStorage.getItem('lang') ?? "ua"
    if (this.$route.query.l) {
      this.changeLanguage()
    }
  },
  methods: {

    changeLanguage() {
      localStorage.setItem("lang", this.languageValue)
      if (this.$i18n.locale !== this.languageValue) {
        this.$i18n.locale = this.languageValue;
      }
    },
    logoUrl() {
      let s = this.organization.logo;
      if (s) {
        return utils.thumbnailImageUrlWithId(s)
      } else {
        return "../assets/issist/logo.svg"
      }
    },
    getUrl(url) {
      return url
    },
    showMenu() {
      if (this.organization.twitter) {
        return true
      }
      if (this.organization.facebook) {
        return true
      }
      if (this.organization.instagram) {
        return true
      }
      return false
    },
    companyLink() {
      return `/`
    }
  }
};
</script>

<style scoped>
.thumb {
  height: 60px;
  border-radius: 10% !important;
}

.width-100 {
  width: 100%;
}

.bg-default {
  background-color: #000000 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #ffffff;
}</style>