<template>
    <div class="profile-page">

        <section class="section-profile-cover section-shaped my-0 hover">
            <div class="shape shape-style-1 shape-primary shape-skew alpha-4" :style="`background: ${staff.color} 50%`">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile " no-body>
                    <div class="px-4">
                        <div class="row justify-content-center">
                            <div class="col-lg-3">
                                <div class="card-profile-image">
                                    <img v-show="staff" style="background: white;" v-lazy="staff.image ? getImageData(staff.image) : `img/theme/default-user.png`"
                                        class="rounded-circle thumb">
                                </div>
                            </div>

                        </div>
                        <div class="text-center mt-5 py-5 ">

                            <h3 class="mt-5">{{ staff.name }}</h3>

                            <div>{{ staff.position }}</div>
                            <div class="align-baseline mt-2">

                                <star-rating :inline="true" :increment="0.1" :star-size="16" :read-only="true" :show-rating="false"
                                    :rating="staff.rate" :max-rating="5"></star-rating>
                            </div>
                        </div>

                    </div>
                </card>
            </div>
        </section>
        <section class="section ">
            <router-view></router-view>

            <div class="container">


                <div class="px-4">
                    <div class="col-md-0 ml-auto mr-auto">
                        <div class="media-area">
                            <h3 id="comments" class="title text-center my-3"><small>{{ $t(getCommentTitle()) }}</small></h3>
                            <div v-for="comment in comments" :key="comment.createdAt"
                                class="media pt-3 min-h-150 border-top">
                                <a class="pull-left">
                                    <div class="avatar mx-2"><img :alt="comment.clientName"
                                            v-lazy="getImageData(comment.clientImage)" class="media-object img-raised">
                                    </div>
                                </a>
                                <div class="media-body">
                                    <h5 class="media-heading">{{ comment.clientName }}<small class="pull-right text-muted">
                                            <star-rating :inline="true" :star-size="16" :read-only="true"
                                                :show-rating="false" :rating="comment.rate" :max-rating="5"></star-rating>
                                        </small>
                                    </h5>
                                    <span>
                                        <p>{{ comment.comment || "ㅤㅤㅤ" }}</p>
                                    </span>
                                    <small class="pull-right text-muted">{{ comment.createdAt | formatDate }}</small>
                                </div>
                            </div>
                            <base-pagination v-show="commentPages > 1" :value="currentPage" :pageCount="commentPages"
                                @input="pageSet" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import router from "@/router";
import utils from "@/utils";
import { FETCH_COMPANY_DETAILS, FETCH_STAFF_DETAILS, FETCH_COMMENTS, FETCH_COMMENTS_PAGES_COUNT } from "@/store/actions.type";
import StarRating from "vue-star-rating";

export default {
    name: "StaffList",

    components: {
        StarRating
    },
    data() {
        return {
            currentPage: 1
        }
    },
    computed: {

        ...mapGetters(['organization', 'staff', 'comments', 'commentPages'])
    },
    beforeRouteEnter(to, from, next) {
        
        if (to.params.key == null) {
            to.params.key = utils.companyName()
        }
        to.params.from = ((to.query.p || 1) - 1) * 10
        to.params.to = ((to.query.p || 1)) * 10
        to.params.offset = 10
        // to.currentPage = to.query.p || 1


        Promise.all([
            store.dispatch(FETCH_COMPANY_DETAILS, to.params.key),
            store.dispatch(FETCH_STAFF_DETAILS, to.params),
            store.dispatch(FETCH_COMMENTS, to.params),
            store.dispatch(FETCH_COMMENTS_PAGES_COUNT, to.params),
        ]).then(() => {

            next();
        }).catch((error) => {
            console.log(error.message)
            router.push({ path: `/` });
        });
    },
    metaInfo() {
        return {
            title: this.staff.name + " ・ " + this.staff.position,
            meta: [
                {
                    name: 'description',
                    content: 'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'
                },
                { property: 'og:title', content: this.organization.name },
                { property: 'og:site_name', content: this.organization.name },
                { property: 'og:image', content: this.getImageData(this.organization.logo) },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' }
            ]
        }
    },
    methods: {
        getCommentTitle() {
            if (this.comments.length != 0) {
                return 'issist.comments.comments'
            } else {
                return 'issist.comments.noComments'
            }
        },
        pageSet(page) {
            this.$route.params.from = (page - 1) * 10
            this.$route.params.to = (page) * 10
            console.log(page);
            this.currentPage = page
            // this.$router.replace({ name: "comments", query: {p: page} })

            Promise.all([
                store.dispatch(FETCH_COMMENTS, this.$route.params),
            ]).catch((error) => {
                console.log(error.message)
                // router.push({ path: `/company/${this.$route.params.key}` });
            });
            document.getElementById("comments").scrollIntoView();
        },
        getImageData(key) {
            if (key) {
                return utils.thumbnailImageUrlWithIdAndSize(key, 'm')
            } else {
                return `/img/theme/default-user.png`
            }
        },
        getColumnSizeStyle() {
            if (this.activeStaff.length <= 3) {
                return `col-lg-${12 / this.activeStaff.length}`
            } else {
                return "col-lg-3"
            }
        },
        logoUrl() {
            let s = this.organization.logo;
            if (s) {
                return utils.thumbnailImageUrlWithId(s)
            } else {
                return "../assets/issist/logo.svg"
            }
        },
        getUrl(url) {
            return url
        },
    }
}
</script>

<style scoped>
.thumb {
    width: 180px;
    height: 180px;
    object-fit: cover;
    /* Do not scale the image */
    object-position: center;
    /* Center the image within the element */
}

.max-width-800 {
    max-width: 800px;
}

.card-profile {
    border-radius: 1.25rem;
}

@media (max-width: 575.98px) {

    .card-margin-top {
        margin-top: 110px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
}

.hover {
    background: #38242488;
}

.min-h-150 {
    min-height: 150px;
}

.row-flex-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 600px;
    }
}

@media (min-width: 768px) {
    .px--5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 600px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 600px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 600px;
    }
}


</style>