<template>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a class="nav-link nav-link-icon" href="#">
                <i class="fa fa-facebook-square"></i>
                <span class="nav-link-inner--text d-lg-none">Facebook</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link nav-link-icon" href="#">
                <i class="fa fa-twitter"></i>
                <span class="nav-link-inner--text d-lg-none">Twitter</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link nav-link-icon" href="#">
                <i class="fa fa-google-plus"></i>
                <span class="nav-link-inner--text d-lg-none">Google +</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link nav-link-icon" href="#">
                <i class="fa fa-instagram"></i>
                <span class="nav-link-inner--text d-lg-none">Instagram</span>
            </a>
        </li>
    </ul>
</template>
<script>
export default {};
</script>
<style>
</style>
