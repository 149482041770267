<template>
  <section class="section section-shaped section-lg my-0 h-85vh">

    <div class="px-4">

      <div class="container pt-lg-md">
        <div class="row justify-content-center">
          <div class="col-lg-5 mx-2">

            <h1 class="fs-4">{{ $t('issist.thanks.forRate') }}</h1>
            <h3>{{ $t('issist.thanks.feedbackHelp') }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {FETCH_COMPANY_DETAILS} from "@/store/actions.type";

export default {
  name: "ThanksForComment",

  computed: {
    ...mapGetters(['organization'])
  },
  beforeRouteEnter(to, from, next) {

    Promise.all([
      store.dispatch(FETCH_COMPANY_DETAILS, to.params.key),
    ]).then(() => {
      next();
    }).catch((error) => {
      console.log(error.message)
      // router.push({path: '/404'});
    });
  },
  beforeMount() {
    // if (!this.organization.logo()) {
    //   router.push({path: '/404'});
    // }
  }
}

</script>

<style scoped>

</style>